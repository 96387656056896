import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Arrow from "../icons/arrow";

const LogoBlock = ({ text, link, linkText, logoImage }) => {
  return (
    <section className={`py-10 md:py-20`}>
        <div className={`mx-auto max-w-screen-xl w-full px-6`}>
            <div className={`flex w-full flex-wrap md:flex-nowrap justify-center items-center`}>
                <div className={`w-full md:w-1/4 md:py-6 h-full`}>
                    <div className={`md:pr-20 h-full w-48 md:w-full`}>
                        <GatsbyImage
                        image={getImage(logoImage)}
                        className={"w-full h-full object-cover"}
                        layout="fullWidth"
                        />
                    </div>
                </div>
                <div className={`w-full md:w-3/4 py-6 md:pl-20 md:border-l border-[#E2E2E2]`}>
                    <h2 className="mb-6 text-blue">{text}</h2>
                    <Link className={`text-[#448FDE] flex items-center`} to={link}>
                        <span className="block mr-4">{linkText}</span><Arrow color={`#448FDE`} />
                    </Link>
                </div>
            </div>
        </div>
    </section>
  );
};

LogoBlock.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    text: PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string,
};

export default LogoBlock;
