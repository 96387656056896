import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/Seo";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import Arrow from "../icons/arrow";
import LogoBlock from "../components/LogoBlock";
import Testimonials from "../components/Testimonials";
// import Popup from '../components/Popup';

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const isImageShown =
    frontmatter.imageText.imageToggle === undefined
      ? false
      : frontmatter.imageText.imageToggle;

  return (
    <Layout>
      {/*
      <Popup
        link="https://findurfit.heymarvelous.com/product/61533"
      />
      */}
      <Seo
        title={frontmatter.hero.title}
        description={frontmatter.intro.text}
      />
      <Hero
        image={frontmatter.hero.image}
        title={frontmatter.hero.title}
        link={frontmatter.hero.link}
        linkText={frontmatter.hero.linkText}
      />
      <Intro
        introImage={frontmatter.intro.introImage}
        title={frontmatter.intro.title}
        text={frontmatter.intro.text}
        link={frontmatter.intro.link}
        linkText={frontmatter.intro.linkText}
        introToggle={
          frontmatter.intro.introToggle ? frontmatter.intro.introToggle : false
        }
      />
      <LogoBlock
        logoImage={frontmatter.logoBlock.logoImage}
        text={frontmatter.logoBlock.text}
        link={frontmatter.logoBlock.link}
        linkText={frontmatter.logoBlock.linkText}
      />
      <div className={`bg-[#F7F7F7] md:h-[590px] relative mb-10 md:mb-20`}>
        <div
          className={`md:absolute h-[250px] top-0 w-full md:w-1/2 md:h-full ${
            isImageShown ? "left-0" : "right-0"
          }`}
        >
          <GatsbyImage
            image={getImage(frontmatter.imageText.imageTextImage)}
            className={"w-full h-full object-cover"}
            layout="fullWidth"
          />
        </div>

        <div
          className={`mx-auto max-w-screen-xl w-full px-6 h-full flex flex-col justify-center ${
            isImageShown ? " items-end" : ""
          }`}
        >
          <div
            className={`flex flex-col justify-center w-full h-full py-12 md:py-0 md:w-1/2 ${
              isImageShown ? "md:pl-14" : " md:pr-14"
            }`}
          >
            {frontmatter.imageText.title && (
              <h2
                className={`text-blue ${
                  frontmatter.imageText.text || frontmatter.imageText.link
                    ? "mb-4 md:mb-8"
                    : "py-4 md:py-0 text-xl md:text-3xl md:leading-10"
                }`}
              >
                {frontmatter.imageText.title}
              </h2>
            )}
            {frontmatter.imageText.text && (
              <div>{frontmatter.imageText.text}</div>
            )}
            {frontmatter.imageText.link && (
              <Link
                to={frontmatter.imageText.link}
                className="flex items-center mt-10 text-blue"
              >
                <span className={`inline-block mr-8`}>
                  {frontmatter.imageText.linkText}
                </span>
                <Arrow />
              </Link>
            )}
          </div>
        </div>
      </div>
      <Testimonials testimonials={frontmatter.testimonials} />
      {/* <Instagram /> */}
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        imageText: PropTypes.shape({
          image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
          title: PropTypes.string,
          text: PropTypes.string,
          link: PropTypes.string,
          linkText: PropTypes.string,
          imageToggle: PropTypes.bool,
        }),
      }),
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        hero {
          title
          link
          linkText
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        intro {
          title
          text
          link
          linkText
          introToggle
          introImage {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        logoBlock {
          text
          link
          linkText
          logoImage {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        imageText {
          title
          text
          link
          linkText
          imageToggle
          imageTextImage {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        testimonials {
          quote
          author
        }
      }
    }
  }
`;
