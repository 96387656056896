import * as React from "react";
import PropTypes from "prop-types";
import { Lazy, Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const Testimonials = ({ testimonials }) => {
  const swiperRef = React.useRef(null);
  return (
    <section className={`bg-green text-white`}>
      <div className={`mx-auto text-center max-w-screen-xl w-full px-6 py-12`}>
        <div className={`relative`}>
          <h2 className={`mb-6 font-medium`}>Client Testimonials</h2>

          <button
            className={`hidden md:block absolute top-1/2 left-0 z-10`}
            id="previousButton"
            onClick={() => swiperRef.current.swiper.slidePrev()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.559"
              height="17.371"
              viewBox="0 0 10.559 17.371"
            >
              <g
                id="Group_1143"
                data-name="Group 1143"
                transform="translate(9.072 15.883) rotate(180)"
              >
                <line
                  id="Line_245"
                  data-name="Line 245"
                  x1="0.546"
                  y1="10.179"
                  transform="translate(7.584 7.197) rotate(135)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2"
                />
                <line
                  id="Line_246"
                  data-name="Line 246"
                  y1="10.179"
                  x2="0.546"
                  transform="translate(0.386 14.395) rotate(-135)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2"
                />
              </g>
            </svg>
          </button>
          <button
            className={`hidden md:block absolute top-1/2 right-0 z-10 rotate-180`}
            id="nextButton"
            onClick={() => swiperRef.current.swiper.slideNext()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.559"
              height="17.371"
              viewBox="0 0 10.559 17.371"
            >
              <g
                id="Group_1143"
                data-name="Group 1143"
                transform="translate(9.072 15.883) rotate(180)"
              >
                <line
                  id="Line_245"
                  data-name="Line 245"
                  x1="0.546"
                  y1="10.179"
                  transform="translate(7.584 7.197) rotate(135)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2"
                />
                <line
                  id="Line_246"
                  data-name="Line 246"
                  y1="10.179"
                  x2="0.546"
                  transform="translate(0.386 14.395) rotate(-135)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2"
                />
              </g>
            </svg>
          </button>
          <Swiper
            ref={swiperRef}
            modules={[Lazy, Autoplay, Pagination]}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            pagination={{
              dynamicBullets: true,
              clickable: true
            }}
            spaceBetween={0}
            lazy={true}
            autoHeight={true}
            slidesPerView={1}
            loop={true}
            preventClicksPropagation={false}
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className={`pb-8 max-w-4xl m-auto px-6 text-md md:text-lg font-medium`}>
                  {testimonial.quote}
                </div>
                <div className={`text-sm`}>
                  <strong className={`font-semibold`}>{testimonial.author}</strong> - FindurFit Member
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string,
    })
  ),
};

export default Testimonials;
